











































































































































































































import { PageBase } from "@/core/models/shared";
import { Component, Prop, Watch} from "vue-property-decorator";
import { Receita } from "@/core/models/financeiro"
import { ReceitaService, TipoReceitaService } from "@/core/services/financeiro"
import { ClienteService } from "@/core/services/geral";
import { Cliente } from "@/core/models/geral";
import { EmbarcacaoService } from "@/core/services/operacional";
import { AlertExcludeQuestion, AlertSimpleErr } from "@/core/services/shared/AlertService";

@Component
export default class ListaReceita extends PageBase {
    search: any = '';
    loading: boolean = false;
    dialogCadastro: boolean = false;
    excelDialog: boolean = false;
    imprimirDialog: boolean = false;
    excelOpcao: string = "padrao";
    imprimirOpcao:string = "Embarcação"
    totalLista: number = 0;
    lista: any[] = [];
    sheet: boolean = false;
    options: any = {
        itemsPerPage: 15
    };
    headers: any[] = [
        { text: '',value:'actions' ,sortable: false },
        { text: 'RO', value: 'registroOperacional.numero', type: 'number'},
        { text: 'Data Atendimento', value: 'registroOperacional.atracacao.dataHoraEntrada', type: 'date', sortable: false },
        { text: 'Embarcação', value: 'registroOperacional.embarcacao.nome', sortable: false},
        { text: 'Cliente', value: 'cliente.nome'},
        { text: 'NF', value: 'notaFiscal'},
        { text: 'Fat', value: 'fatLocacao'},
        { text: 'Data Emissão', value: 'dataEmissao', type: 'date'},
        { text: 'Valor', value: 'total', type: 'number', sortable: false},
        { text: 'Imposto', value: 'totalImposto', type: 'number', sortable: false},
        { text: 'Geral', value: 'totalGeral', type: 'number', sortable: false},
        { text: 'Data Pagamento / Vencimento', value: 'dataPagamento', type: 'date'}
    ];
    
    filtroReceitaDespesa: any = {
        numeroRo: null,
        embarcacaoId:null,
        clienteId: null,
        tipoReceitaId: null,
        nf : null,
        dataAtendimentoInicio: null,
        dataAtendimentoFim: null,
        dataEmissaoInicio: null,
        dataEmissaoFim: null,
        dataInicio: null,
        dataFim: null,
    };

    item = new Receita();
    service = new ReceitaService();

    clienteService = new ClienteService();
    clientes: Cliente[] = [];
    isClienteLoading: boolean = false;
    onSearchCliente: any = null;

    embarcacaoService = new EmbarcacaoService();
    embarcacoes: any[] = [];
    isEmbarcacaoLoading: boolean = false;
    onSearchEmbarcacao: any = null;

    tipoService = new TipoReceitaService();
    tipoReceitas: any[] = [];
    isTipoLoading: boolean = false;
    onSearchTipo: any = null;

    @Watch('options', { deep: true })
    Atualizar(isFiltro: boolean = true){

        if(isFiltro == true)
        {
            this.options.page = 1;
        }

        const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.options;
        this.loading = true;
        this.sheet = false;

        this.service.ListarComFiltro(page, itemsPerPage, sortBy, sortDesc, this.search, this.headers, this.filtroReceitaDespesa, "RegistroOperacional.Embarcacao, Cliente,Tipo,Categoria,EquipamentoServico").then(
        res => {
            this.lista = res.data.items;
            this.totalLista = res.data.count;
        },
        err => {
            AlertSimpleErr("Aviso", err);
        })
        .finally(() => (this.loading = false));
    }

    @Watch('onSearchCliente')
    SearchCliente (val: string) {
        if (this.isClienteLoading) return;
        if (this.filtroReceitaDespesa.clienteId) return;
        if (!val) return;
        this.isClienteLoading = true
        this.clienteService.AutoComplete(val)
        .then(
            res => {
                this.clientes = res.data;
            },
            err => AlertSimpleErr("Aviso", err)
        )
        .finally(() => (this.isClienteLoading = false));
    }

    @Watch('onSearchEmbarcacao')
    SearchEmbarcacao (val: string) {

        if (this.isEmbarcacaoLoading) return;
        if (this.filtroReceitaDespesa.embarcacaoId) return;
        if (!val) return;

        this.isEmbarcacaoLoading = true
        this.embarcacaoService.AutoComplete(val)
        .then(
            res => {
                this.embarcacoes = res.data;
            },
            err => AlertSimpleErr("Aviso", err)
        )
        .finally(() => (this.isEmbarcacaoLoading = false));
    }

    mounted() {
        this.tipoService.ListarTudo().then(
            res =>{
                this.tipoReceitas = res.data.items;
                this.tipoReceitas.unshift(
                    {
                        id: null,
                        nome: "TODOS"
                    }
                )
            },
            err => AlertSimpleErr("Aviso", err)
        )   
    }

    AbrirDialogCadastro(item?: Receita){
        if(item){
            this.service.ObterPorId(item.id, "RegistroOperacional.Proposta.Cliente, Cliente, Tipo,Categoria,EquipamentoServico").then(
                res=>{
                    this.item = new Receita(res.data);
                    this.dialogCadastro = true;
                },
                err => AlertSimpleErr("Aviso", err)
            )
        }
        else{
            this.item = new Receita();
            this.item.empresaId = this.app.empresaId;
            this.dialogCadastro = true;
        }
    }

    Imprimir(opcao:string){
        if(opcao == "Embarcação"){
        let routeLink = this.$router.resolve({ name: 'relatorioReceita',
            query:{
                numeroRo: this.filtroReceitaDespesa.numeroRo,
                clienteId: this.filtroReceitaDespesa.clienteId,
                embarcacaoId: this.filtroReceitaDespesa.embarcacaoId,
                tipoReceitaId: this.filtroReceitaDespesa.tipoReceitaId,
                dataInicio: this.filtroReceitaDespesa.dataInicio,
                dataFim: this.filtroReceitaDespesa.dataFim
            }
        });
        window.open(routeLink.href, '_blank');
        }
        else{
            let routeLink = this.$router.resolve({ name: 'relatorioReceita2',
            query:{
                numeroRo: this.filtroReceitaDespesa.numeroRo,
                clienteId: this.filtroReceitaDespesa.clienteId,
                embarcacaoId: this.filtroReceitaDespesa.embarcacaoId,
                tipoReceitaId: this.filtroReceitaDespesa.tipoReceitaId,
                dataInicio: this.filtroReceitaDespesa.dataInicio,
                dataFim: this.filtroReceitaDespesa.dataFim
            }
        });
        window.open(routeLink.href, '_blank');
        }
    }

    GerarExcel(opcao: string){
        if(opcao == "padrao"){
            this.ExportarExcel('tabela');
        }
        else{
            this.service.ExcelEquipamentoReceita(this.filtroReceitaDespesa.numeroRo, this.filtroReceitaDespesa.clienteId, this.filtroReceitaDespesa.embarcacaoId, this.filtroReceitaDespesa.tipoReceitaId, this.filtroReceitaDespesa.dataInicio, this.filtroReceitaDespesa.dataFim).then(
                res =>{
                    this.JsonToCSV(res.data, "Receitas", true);
                },
                err => AlertSimpleErr("Aviso", err)
            )
        }
        this.excelDialog = false;
    }

    Excluir(item: Receita){
        const context = this;
        const excluir = function () {
            return new Promise( async function (resolve, reject){
                await context.service.Excluir(item.id).then(
                    res => {
                        if(res.status == 200){
                            return resolve(res.data);
                        }
                    },
                    err => {
                        AlertSimpleErr("Aviso", err);
                    }
                ).finally(() => {
                    context.Atualizar();
                })
            });
        }
        AlertExcludeQuestion(excluir);
    }
}
